import React from 'react'
import LayoutStatic from '../../../components/LayoutStatic';
import { Link } from 'gatsby';

const style = {
  card: {
    width: '18rem',
    margin: 'auto',
    marginTop: '20px',
  },
  button: {
    marginTop: '10px',
    marginRight: '10px',
    width: '100%'
  }
}

const NcdPage = () => {
  return (
    <LayoutStatic>
      <div className="card" style={style.card} >
        <img src="/img/ncd.jpg" className="card-img-top bootstrap-card-img-top" alt="..." />
        <div className="card-body">
          <h5 className="card-title">ประเมินความเสี่ยง โรคทั่วไป</h5>
          <p className="card-text">กลุ่มโรคที่ไม่ได้เกิดจากเชื้อโรคและไม่สามารถแพร่กระจายได้ แต่เป็นโรคที่เกิดจากนิสัยหรือพฤติกรรมการดำเนินชีวิต ซึ่งจะมีการดำเนินโรคอย่างช้าๆ ค่อยๆ จนอาการหนัก อย่าปล่อยให้มีผผลกับคุณโดยไม่รู้ตัว ประเมิน ฟรี!!</p>
          {/* <p className="card-text" style={{ color: 'red' }}>(กำลังอยู่ในขั้นตอนการพัฒนา)</p> */}
          <Link to="/questionnaire?questionnaireId=56" className="btn btn-primary" style={style.button}>แบบประเมินความเสี่ยงโรคหัวใจและหลอดเลือด</Link>
          <Link to="/questionnaire?questionnaireId=57" className="btn btn-primary" style={style.button}>แบบประเมินความเสี่ยงมะเร็งตับ</Link>
          <Link to="/questionnaire?questionnaireId=58" className="btn btn-primary" style={style.button}>แบบประเมินความเสี่ยงมะเร็งลำไส้ใหญ่ จากข้อมูลศูนย์วิจัยสุขภาพกรุงเทพ พ.ศ. 2557</Link>
          <Link to="/questionnaire?questionnaireId=59" className="btn btn-primary" style={style.button}>ประเมินความเสี่ยงการเกิดโรคหลอดเลือดสมอง (อัมพฤกษ์,อัมพาต)</Link>
          <Link to="/questionnaire?questionnaireId=60" className="btn btn-primary" style={style.button}>แบบประเมินความเสี่ยงเบาหวาน สำหรับอายุ 35 ปีขึ้นไป</Link>
          <Link to="/questionnaire?questionnaireId=61" className="btn btn-primary" style={style.button}>แบบประเมินความเสี่ยงมะเร็งเต้านม จากข้อมูลศูนย์วิจัยสุขภาพกรุงเทพ พ.ศ. 2557</Link>
          <Link to="/questionnaire?questionnaireId=62" className="btn btn-primary" style={style.button}>แบบคัดกรองโรคพาร์กินสัน โดย รศ.นพ.วีรศักดิ์ เมืองไพศาล</Link>
          <Link to="/questionnaire?questionnaireId=63" className="btn btn-primary" style={style.button}>แบบประเมินพฤติกรรมการบริโภคอาหาร หวาน มัน เค็ม (Eat Test ) จาก สสส.</Link>
          {/* <Link to="/questionnaire?questionnaireId=45" className="btn btn-primary" style={style.button}>แบบคัดกรองสุขภาวะทางตา</Link>
          <Link to="/questionnaire?questionnaireId=46" className="btn btn-primary" style={style.button}>แบบคัดกรองภาวะการรู้คิดในผู้สูงอายุ</Link>
          <Link to="/questionnaire?questionnaireId=48" className="btn btn-primary" style={style.button}>คัดกรองจำแนกระดับโรคความดันโลหิตสูง</Link> */}
          
          {/* <Link to="#" className="btn btn-primary" style={style.button}>ตรวจความเสี่ยง อัลไซม์เมอร์</Link>
          <Link to="#" className="btn btn-primary" style={style.button}>กระดูก และ ข้อ</Link>
          <Link to="#" className="btn btn-primary" style={style.button}>เบาหวาน</Link>
          <Link to="#" className="btn btn-primary" style={style.button}>โรค พาคินสัน</Link>
          <Link to="#" className="btn btn-primary" style={style.button}>โรค ไทรอยด์</Link> */}
          <p></p>
          {/* <p className="card-text" style={{ color: 'blue' }}>(แบบคัดกรองอื่นๆ..กำลังพัฒนา)</p> */}
        </div>
      </div>
    </LayoutStatic>
  );
}

export default NcdPage;

